@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply font-montserrat; /* Use Montserrat globally */
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F9FAFB  !important;
  background-color:#F9FAFB !important

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  height: 100vh;
  /* display: flex; */
  justify-content: center;
}
.headerContainer{
  padding-top: 5%;
}
.textHeader{
  background-color: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-radius: 8px;
  width: 68vw;
  padding-inline: 25px;
  padding-block: 18px;
  font-family: Montserrat;
font-size: 14px;
font-weight: 400;
line-height: 18px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
.button{
  padding-block: 18px;
  padding-right: 13px;
  padding-left: 8px;
  border-radius: 36px;
  background-color: #FFFFFF;
}